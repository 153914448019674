import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
const services = [
    { id: 'reconocimiento-facial', name: 'Reconocimiento facial' },
    { id: 'reconocimiento-voz', name: 'Reconocimiento de Voz' },
    { id: 'pwa', name: 'Progressives Web App´s' },
    { id: 'iot', name: 'Internet de las Cosas IoT' },
    { id: 'inteligencia-artificial', name: 'Inteligencia Artificial' },
    { id: 'industrias', name: 'Para diferentes industrias' },
    { id: 'salud', name: 'Salud' },
    { id: 'educacion', name: 'Educación' },
    { id: 'seguridad', name: 'Seguridad' },
    { id: 'otros', name: 'Y mucho mas...' },
];
const ServiceSelector = ({ }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = (id) => {
        const element = document.getElementById(id);
        console.log('element', element);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        [
            <Button
                onClick={(e) => setAnchorEl(!anchorEl ? e.target : null)}
                style={{ height: '100%', padding: '0px 16px', color: 'black' }}
            >
                {'Servicios'}
            </Button>,
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {services.map((service) => (
                    <MenuItem
                        key={service.id}
                        onClick={() => handleClick(service.id)}
                        style={{ margin: '10px', padding: '10px 20px', cursor: 'pointer' }}
                    >
                        {service.name}
                    </MenuItem>
                ))}
            </Menu>
        ]
    );
};

export default ServiceSelector;
