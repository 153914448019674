import React from 'react';
import { motion } from 'framer-motion';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());


const contain = [
  {
    title: '',
    content: 'contactanos',
    color: 'white'
  },
  {
    video: '/videos/ideas.mp4',
    title: '',
    content: 'Bienvenidos a la nueva era digital',
    animation: batch(Fade(), Sticky(), MoveOut(0, -200))
  },
  {
    video: '/videos/company.mp4',
    title: '',
    content: 'Inamex',
    animation: ZoomInScrollOut
  },
  {
    video: '/videos/innovation.mp4',
    title: '',
    content: 'Te acompañamos al éxito en tu operación',
    animation: FadeUp,
  },
  {
    video: '/videos/savings.mp4',
    title: '',
    content: 'Innovación\nOptimización',
    animation: FadeUp,

  },
  {
    video: '/videos/company.mp4',
    title: '',
    content: 'Diferentes tecnologías',
    animation: ZoomInScrollOut
  },
  {
    video: '/videos/face-id.mp4',
    title: '',
    content: 'Reconocimiento facial'
  },
  {
    video: '/videos/voice.mp4',
    title: '',
    content: 'Reconocimiento de Voz'
  },
  {
    video: '/videos/pwa.mp4',
    title: '',
    content: 'Progressives Web App´s'
  },
  {
    video: '/videos/iot.mp4',
    title: '',
    content: 'Internet de las Cosas IoT'
  },
  {
    video: '/videos/ia.mp4',
    title: '',
    content: 'Inteligencia Artificial'
  },
  {
    title: '',
    content: 'Para diferentes industrias'
  },
  {
    video: '/videos/thin-k.mov',
    title: '',
    content: 'Salud'
  },
  {
    video: '/videos/security.mp4',
    title: '',
    content: 'Seguridad'
  },
  {
    video: '/videos/company.mp4',
    title: '',
    content: ''
  },
  {
    title: '',
    content: '',
    color: 'white'
  },
  {
    title: '',
    content: '',
    color: 'white'
  }
]
const AboutUs = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
});
  return (
      <div style={{
        width: '100%', overflow: 'hidden',
        backgroundColor: 'white'
      }}>
        {/* <div style={{ width: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden' }}>
          {
            contain.map((item, index) => {
              if (!item.video) {
                return (
                  <div style={{ height: '100vh', width: '100%', backgroundColor: item.color || 'transparent' }} />
                )
              }
              return (
                <video key={index} autoPlay muted loop style={{ height: '100vh', opacity: 0.5 }}>
                  <source src={item.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )
            })
          }
        </div> */}
        <ScrollContainer style={{ width: '100%' }}>
          {/* {
            contain.map((item, index) => {
              return (
                <ScrollPage key={index}>
                  <Animator animation={item.animation}>
                    <span style={{ color: 'gray', fontSize: isMd ? "40px": '35', whiteSpace: 'pre-line', textAlign: 'center' }}>{item.content}</span>
                  </Animator>
                </ScrollPage>
              )
            })
          } */}
          <ScrollPage>
            <Animator animation={FadeUp}>
              <Box>
                <img width={'100%'} src='images/toolBar/logo.png' alt='' />
              </Box>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px', textAlign: 'center' }}>¿Quienes Somos?</span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={ZoomInScrollOut}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>Inamex</span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={FadeUp}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>¿Quienes Somos?</span>
              <br />
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px' }}>
                {'Antes que una empresa, somos un equipo interdisciplinario de personas con un proyecto compartido: Aplicar innovación en América Latina para mejorar la calidad de vida de sus habitantes.'}
              </span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={ZoomInScrollOut}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}></span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={FadeUp}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>¿Que Hacemos?</span>
              <br />
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px' }}>
                {'Sabemos de que todo proceso de transformación organizacional actual involucra de alguna manera tecnología, pero que la tecnología en sí misma no agrega valor si no se adapta en función del contexto de aplicación. Por esto trabajamos sobre dos ejes para conseguir nuestro propósito'}
              </span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={ZoomInScrollOut}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}></span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={FadeUp}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>Misión</span>
              <br />
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px' }}>
                {'POTENCIALIZAR A NUESTROS CLIENTES A TRAVÉS DE LA TECNOLOGÍA Y LA INNOVACIÓN. BRINDAR SOLUCIONES VANGUARDISTAS Y DISRUPTIVAS EN LAS DIFERENTES INDUSTRIAS Y FLUJOS DE TRABAJO. SATISFACER LAS NECESIDADES Y SUPERAR LAS EXPECTATIVAS DE NUESTROS CLIENTES'}
              </span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={ZoomInScrollOut}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}></span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={FadeUp}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>Visión</span>
              <br />
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px' }}>
                {'SER RECONOCIDA COMO LÍDER EN EL MERCADO DE INTEGRACIÓN DE SOLUCIONES TECNOLÓGICAS EN TODO TIPO DE INDUSTRIAS. CREAR ALIANZAS ESTRATÉGICAS CON PROVEEDORES DE PRESTIGIO MUNDIAL PARA DISEÑAR SOLUCIONES DE VANGUARDIA'}
              </span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={ZoomInScrollOut}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}></span>
            </Animator>
          </ScrollPage>
          <ScrollPage>
            <Animator animation={FadeUp}>
              <Box>
                <img width={'100%'} src='images/toolBar/logo.png' alt='' />
              </Box>
              <Button variant='outlined' size='large' fullWidth onClick={() => {
                window.open(`https://api.whatsapp.com/send?phone=525527274278&text=${'¡Hola INAMEX me interesan sus servicios!'}`, '_blank');
              }}>
                {'Contactanos'}
              </Button>
            </Animator>
          </ScrollPage>
          {/* <ScrollPage>
            <Animator animation={batch(Fade(), Sticky())}>
              <span style={{ color: 'gray', fontSize: isMd ? "40px": '35' }}>Beacon´s</span>
              <br />
              <span style={{ color: 'gray', fontSize: isMd ? "30px" : '15px' }}>
                Conoce como transformamos con tecnología tu operación
              </span>
            </Animator>
          </ScrollPage> */}
        </ScrollContainer>
      </div>
    
  )
};

export default AboutUs;
