import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconMask from '../IconMask';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import app from '../../services';
import firebase from '../../services/firebase';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, useMediaQuery } from '@mui/material';
import useMeasure from 'react-use-measure';
import Login from '../Login';
import { removeGlobalUser, removeInstitution, removeToken, setInstitution } from '../../redux/session';
import ThemeButton from '../customForms/ThemeButton';
import CatalogsIconButton from '../customForms/CatalogsIconButton';
import { citasUsuarios, instituciones, meetUsuarios, meets } from '../../constants/models';
import { Helmet } from 'react-helmet';
import './MiniDrawer.css';
import { ShoppingBagOutlined } from '@mui/icons-material';
import ServiceSelector from './ServiceSelector';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: 0,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const routes = [
  { name: 'Inicio', path: '/home', icon: 'assets/icons/connect.svg' },
  { name: 'Configuración', path: '/config', icon: 'assets/icons/settings.svg' },
  { name: 'Dashboard', path: '/viewer', icon: 'assets/icons/panel.svg', hidden: true }
];

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [ref, bounds] = useMeasure()
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [path, setPath] = React.useState(history.location.pathname);
  const token = useSelector((state) => state.session.token);
  const [roles, setRoles] = React.useState([]);
  const [routesFilter, setRoutesFilter] = React.useState([]);
  const [meet, setMeet] = React.useState(null);
  const [institutions, setInstitutions] = React.useState([]);
  const user = useSelector((state) => state.session.globalUser);
  const institution = useSelector((state) => state.session.institution);
  const dispatch = useDispatch();
  const [newInstitution, setNewInstitution] = React.useState();
  const [showLogin, setShowLogin] = React.useState(!token)
  const rolesService = app.service('users-roles');
  const institutionService = app.service('institutions');

  React.useEffect(() => {
    // console.log('token', showLogin, token);
    if (!!token && showLogin) {
      setShowLogin(false);
      history.push('/')
    } else if (!token && !showLogin) {
      setShowLogin(true);
    }
    console.log('history', history.location.pathname);
    return history.listen((location) => {
      // console.log(`You changed the page to: ${location.pathname}`)
      if (!token && location.pathname !== '/') {
        setShowLogin(true);
      } else if (location.pathname !== '/' && !showLogin) {
        setShowLogin(false);
      }
      setPath(location.pathname);
    })
  }, [history, token])
  // console.log('path-----', path)
  React.useEffect(() => {
    app.on('disconnect', (e) => {
      console.log('e', e)
    })
    app.reAuthenticate().then(() => {
      // show application page
      console.log('authenticate')
    }).catch(() => {
      // show login page
      console.log('no authenticate')
      setShowLogin(true);
      dispatch({ type: 'UNSET_USER', payload: null });

    });
  }, [])
  const logging = async () => {
    // await firebase.auth().signInAnonymously()
    if ('Notification' in window) {
      // Solicita permisos para las notificaciones
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Permiso de notificaciones concedido.');
            // Ahora puedes usar Firebase para obtener el token de registro
            // y enviarlo al servidor para enviar notificaciones push en el futuro
            firebase.messaging().getToken()
              .then((token) => {
                console.log('Token de registro:', token, user);
                const service = app.service('usuarios')
                service.patch(user.id, { vc_push: token })
                  .then(updatedItem => {
                    console.log('Item updated:', updatedItem);
                  })
                  .catch(error => {
                    console.error('Error updating item:', error);
                  });
                // Envía este token al servidor para enviar notificaciones push a este dispositivo en el futuro
              })
              .catch((error) => {
                console.log('Error al obtener el token de registro:', error);
              });
          } else if (permission === 'denied') {
            console.log('Permiso de notificaciones denegado.');
          } else if (permission === 'default') {
            console.log('El usuario cerró la ventana de permisos sin tomar una decisión.');
          }
        })
        .catch((error) => {
          console.log('Error al solicitar permiso de notificaciones:', error);
        });
    } else {
      console.log('Las notificaciones no son compatibles en este navegador.');
    }
  }
  const getInstitutions = async () => {
    console.log('institutions ------->');
    setInstitutions([]);
    const res = await institutionService.find();
    console.log('res ------->', res);
    setInstitutions(res.data);
  }
  const getRoles = async () => {
    console.log('Roles ------->');
    setRoles([]);
    const res = await rolesService.find({ query: { $and: [{ id_usuario: user.id }] } });
    console.log('Roles ------->', res);
    setRoles(res.data);
  }
  React.useEffect(() => {
    if (token) {
      getInstitutions();
      getRoles();
      logging();
    }
  }, [token])
  React.useEffect(() => {
    setRoutesFilter(routes.filter(e => e.allowRoles === undefined || (!!e.allowRoles?.filter(i => !!(roles.map(e => e.id).includes(i)).length))))
  }, [roles])
  console.log('history.location.pathname', history.location.pathname)
  const routeSelected = routes.find(e => history.location.pathname === e.path);
  const renderHeaderButton = () => {
    console.log('history', history.location.pathname);
    switch (true) {
      case (history.location.pathname === '/video-call'): {
        console.log('entro a video ----->')
        return <CatalogsIconButton model={meets} icon={'/assets/icons/meet.svg'} color={'white'} backgroundColor={'transparent'} />
      }
      default: {
        break;
      }
    }
  }
  return ([
    <Box key='miniDrawer' sx={{ display: 'flex', height: '100%' }}>
      <Helmet >
        <title>{`INAMEX | ${path === '/' ? 'Inicio' : path}`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <CssBaseline />
      <AppBar ref={ref} position="fixed" open={open} style={{ padding: 0 }}>
        <Toolbar style={{ backgroundColor: 'white' }}>
          <Box key={'header'} style={{ width: '100%', zIndex: 30000 }}>
            <Grid container>
              <Grid key={'logo'} item xs='auto'>
                <img height={60} src={'images/toolBar/logo.png'} alt="logo" />
              </Grid>
              <Grid key={'separator'} item xs />

              {
                !!isMd &&
                <Grid key={'home'} item xs='auto'>
                  <Button onClick={() => {
                    history.push('/')
                  }} style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                    {'Inicio'}
                  </Button>
                </Grid>
              }
              {
                !!isMd &&
                <Grid key={'home'} item xs='auto'>
                  <ServiceSelector/>
                </Grid>
              }
              {
                !!isMd &&
                <Grid key={'about-us'} item xs='auto'>
                  <Button onClick={() => {
                    history.push('about-us')
                  }} style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                    {'Quienes Somos'}
                  </Button>
                </Grid>
              }
              {
                !!isMd &&
                <Grid key={'partners'} item xs='auto'>
                  <Button onClick={() => {
                    history.push('partners')
                  }} style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                    {'Nuestros Clientes'}
                  </Button>
                </Grid>
              }
              {/* <Grid key={'collections'} item xs='auto'>
                <Button style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                  {'Colecciones'}
                </Button>
              </Grid>
              <Grid key={'shoppingCart'} item xs='auto'>
                <Button style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                  <Badge badgeContent={4} color="primary">
                    <ShoppingBagOutlined />
                  </Badge>
                </Button>
              </Grid> */}
              {
                !isMd &&
                <Grid key={'menu'} item xs='auto'>
                  <Button
                    color="inherit"
                    aria-label="open drawer"
                    // onClick={open ? handleDrawerClose : handleDrawerOpen}
                    onClick={(e) => setAnchorEl(!!anchorEl ? null : e.currentTarget)}
                    edge="start"
                    style={{ height: '100%', padding: '0px 16px', color: 'black' }}>
                    <MenuIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => {
                      setAnchorEl(null);
                      history.push('/')
                    }}>Inicio</MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorEl(null);
                      history.push('about-us')
                    }}>Quienes Somos</MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorEl(null);
                      history.push('partners')
                    }}>Nuestros Clientes</MenuItem>
                  </Menu>
                </Grid>

              }

            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader >
          <ListItem key={'title'} disablePadding sx={{ display: 'block', position: 'absolute', left: 0, top: 8 }}>
            <ListItemButton
              disableRipple
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                dispatch(setInstitution(null));
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src="assets/images/logo192.png" style={{ backgroundColor: 'white', height: 35, padding: 2, borderRadius: '50%' }} alt='' />
              </ListItemIcon>
              <ListItemText className='text-container' primary={`${institution?.vc_nombre || 'INAMEX'}`} sx={{ opacity: open ? 1 : 0, overflow: 'scroll' }} />
            </ListItemButton>
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {routesFilter.filter(e => !e.hidden).map((route, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block', backgroundColor: route.path === path ? 'rgba(200,200,200,0.1)' : 'transparent' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => { history.push(route.path) }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <IconMask color={route.path === path ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'gray' : 'black'} url={route.icon} size={25} />
                </ListItemIcon>
                <ListItemText primary={route.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <ThemeButton mode='custom' drawerOpen={open} />
      </Drawer>
      <Box p={0} component="" style={{ paddingTop: bounds.height, width: '100%' }} sx={{ flexGrow: 1, p: 0, height: '100%' }}>
        {props.children}
      </Box>
    </Box>,
    // <Login
    //   key={'login-selector'}
    //   open={showLogin}
    //   setShowLogin={setShowLogin}
    // />,
    <Dialog
      key={'institution-selector'}
      open={!showLogin && !institution}
      BackdropProps={{ style: { backgroundColor: 'black' } }}
    >
      <DialogTitle>
        {'Para iniciar selecciona o crea una empresa'}
      </DialogTitle>
      <DialogContent>
        {
          institutions.map((ins, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  dispatch(setInstitution({ ...ins, roles: roles.filter(e => e.id_institucion === ins.id || e.id_institucion === null) }));
                }}
              >
                <Box paddingRight={3}>
                  <IconMask color={theme.palette.mode === 'dark' ? 'white' : 'black'} url={'/assets/icons/fabrica.svg'} size={25} />
                </Box>
                {ins.vc_nombre}
              </ListItemButton>
            );
          })
        }
      </DialogContent>
      <DialogActions>
        <CatalogsIconButton key={'catIcon'} title={'Crear'} mode='title' model={instituciones} />
        <Button
          key={'aceptar'}
          onClick={() => {
            dispatch(removeToken());
            dispatch(removeGlobalUser());
            dispatch(removeInstitution());
            setInstitutions([]);
          }}
        >
          {'Cerrar Sesión'}
        </Button>
      </DialogActions>
    </Dialog>
  ]
  );
}