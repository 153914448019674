import React, { Suspense, lazy, useEffect } from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import Layout from './Layout';
import store from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import MiniDrawer from './components/MiniDrawer';
import Loading from './common/Loading';
import AboutUs from './views/AboutUs';
import Partners from './views/Partners';
const LoginView = lazy(() => import('./views/LoginView'));
const NoMatch = lazy(() => import('./views/NoMatch'));
const ConfigView = lazy(() => import('./views/ConfigView'));
const CatalogsView = lazy(() => import('./views/CatalogsView'));
const HomeView = lazy(() => import('./views/Home'));

function App() {
  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Layout>
            <MiniDrawer>
              <Switch>
                <Route key={'/'} exact={true} path="/">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <HomeView />
                  </Suspense>
                </Route>
                <Route key={'/home'} path="/home">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <CatalogsView />
                  </Suspense>
              </Route>
              <Route key={'/about-us'} path="/about-us">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <AboutUs />
                  </Suspense>
              </Route>
              <Route key={'/partners'} path="/partners">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <Partners />
                  </Suspense>
                </Route>
                <Route key={'/login'} path="/login">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <LoginView />
                  </Suspense>
                </Route>
                <Route key={'/config'} path="/config">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <ConfigView />
                  </Suspense>
                </Route>
                <Route key={'*'} path="*">
                  <Suspense fallback={<div style={{}}><Loading /></div>}>
                    <NoMatch />
                  </Suspense>
                </Route>
              </Switch>
            </MiniDrawer>
        </Layout>
      </PersistGate>
    </Provider>
  );
}

export default App;